import img1 from './code-placeholder-1.png';
import img2 from './code-placeholder-2.png';
import img3 from './code-placeholder-3.png';
import img4 from './code-placeholder-4.png';
import img5 from './code-placeholder-5.png';
import img6 from './code-placeholder-6.png';
import img7 from './code-placeholder-7.png';
import img8 from './code-placeholder-8.png';
import img9 from './code-placeholder-9.png';
import img10 from './code-placeholder-10.png';
import img11 from './code-placeholder-11.png';
import img12 from './code-placeholder-12.png';
import img13 from './code-placeholder-13.png';
import img14 from './code-placeholder-14.png';
import img15 from './code-placeholder-15.png';
import img16 from './code-placeholder-16.png';
import img17 from './code-placeholder-17.png';
import img18 from './code-placeholder-18.png';
import img19 from './code-placeholder-19.png';
import img20 from './code-placeholder-20.png';

const images = [
	img1,
	img2,
	img3,
	img4,
	img5,
	img6,
	img7,
	img8,
	img9,
	img10,
	img11,
	img12,
	img13,
	img14,
	img15,
	img16,
	img17,
	img18,
	img19,
	img20,
];

export default images;
