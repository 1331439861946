import '../assets/css/cv.scss';

import CvSection from '../components/CvSection';
import CvSectionItem, { ICvSectionItemProps } from '../components/CvSectionItem';

import { Col, Row } from 'reactstrap';
import useWindowDimensions from '../hooks/useWindowDimentions';

const employment: ICvSectionItemProps[] = [
   {
      title: 'Senior Software Engineer, Woebot Health',
      timeline: '2023 - present',
      desc: 'Currently leading development for many key features and mentoring junior team members.',
   },
   {
      title: 'Software Engineer, Woebot Health',
      timeline: '2021 - present',
      desc: 'Primarily responsible for developing and maintaining the core bot logic and backend (TypeScript, AWS).',
   },
   {
      title: 'Junior Software Engineer, Woebot Health',
      timeline: '2020 - 2021',
      desc: 'I assisted in the development of the core bot logic (TypeScript, AWS), CMS (React), and native Android mobile app (Kotlin).',
   },
   {
      title: 'Tutor, Dundalk School of Music',
      timeline: '2012 - 2019',
      desc: 'Provided guitar and music theory tuition, covering the Junior/ Leaving Cert music courses and syllabuses for the ABRSM, RIAM, and RSL exam boards.',
   },
   {
      title: 'Sound Designer, Collaborative Artists’ Collective',
      timeline: '2014 - 2018',
      desc: 'Composed new music, provided sound design, and built custom software for theatre and dance productions.',
   },
];

const education: ICvSectionItemProps[] = [
   {
      title: 'Dublin City University — Higher Diploma in Software Development (1:1)',
      timeline: '2021',
      desc: 'Completed modules in web development (Python, JS, HTML, CSS), Android development (Java, Kotlin), and database design (MySQL, MongoDB).',
   },
   {
      title: 'Maynooth University — MA in Music Composition (1:1)',
      timeline: '2015',
      desc: 'Alongside composition, my main areas of study were in audio programming (Csound, MaxMSP, Pure Data), sound synthesis, and audio signal processing.',
   },
   {
      title: 'Maynooth University — BMus (2:1)',
      timeline: '2011',
      desc: 'Composition (major), performance (minor)',
   },
];

export const Cv = () => {
   const dimensions = useWindowDimensions();

   return (
      <div className="cv">
         <h2 className="title">cv.</h2>
         <Row>
            <Col lg={2} className="section-title">
               Key Skills
            </Col>
            <Col lg={8} className="key-skills">
               <p>
                  TypeScript, JavaScript, Node.js, React, Kotlin, Android SDK, MongoDB, MySQL, Serverless, AWS, Docker
               </p>
            </Col>
         </Row>
         <CvSection
            title={'Employment'}
            items={employment.map((job) => (
               <CvSectionItem
                  dimentions={dimensions}
                  timeline={job.timeline}
                  title={job.title}
                  desc={job.desc}
                  list={job.list}
               />
            ))}
         />
         <CvSection
            title={'Education'}
            items={education.map((school) => (
               <CvSectionItem
                  dimentions={dimensions}
                  timeline={school.timeline}
                  title={school.title}
                  desc={school.desc}
                  list={school.list}
               />
            ))}
         />
      </div>
   );
};

export default Cv;
