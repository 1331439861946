export const onHearingReddishGreen = {
   title: 'On hearing reddish-green',
   key: 'on-hearing-reddish-green',
   instr: 'for solo db.',
   year: 2014,
   tags: ['solo', 'electro-acoustic', 'double bass'],
   jsx: (
      <div className="performance-info">
         <p>
            <span className="font-medium">Premiered by:</span>
            <ul>
               <li>Malachy Robinson (double bass)</li>
               <li>Daniel Reid (laptop)</li>
            </ul>
         </p>
      </div>
   ),
};
