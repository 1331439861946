import img1 from './osc-placeholder-1.png';
import img2 from './osc-placeholder-2.png';
import img3 from './osc-placeholder-3.png';
import img4 from './osc-placeholder-4.png';
import img5 from './osc-placeholder-5.png';
import img6 from './osc-placeholder-6.png';
import img7 from './osc-placeholder-7.png';
import img8 from './osc-placeholder-8.png';
import img9 from './osc-placeholder-9.png';
import img10 from './osc-placeholder-10.png';
import img11 from './osc-placeholder-11.png';
import img12 from './osc-placeholder-12.png';
import img13 from './osc-placeholder-13.png';

const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13];

export default images;
