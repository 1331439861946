import { Container } from 'reactstrap';
import Article from '../components/Article';
import Image from '../components/Image';
import me from '../assets/imgs/dan-reid1.png';

import '../assets/css/about.scss';

const About = () => {
   return (
      <div className="about">
         <Image src={me} alt="daniel reid" />
         <Container>
            <Article title="about.">
               <p>
                  I'm a musician and software developer based in Ireland. As a musician, my main areas of interest
                  include graphic notation, electronics, algorithmic composition, and experimential audio processing
                  techniques. I write both notated and electronic music, as well as audio-visual work.
               </p>
               <p>
                  I studied composition and classical guitar at Maynooth University, but before begining at Maynooth, I
                  studied software development for a year. While I initially enjoyed writing code, I had all but left it
                  behind when I started my undergraduate in music. However, it wasn't until I was in the process of
                  completing an MA in composition, that I realised code and music could be combined in fantastically
                  interesting ways. This is an area I am currently preoccupied with.
               </p>
               <p>
                  As a software developer, I work mostly with web technologies like Node.js, React.js, TypeScript,
                  MongoDB, and AWS, though I also have experience with native Android and audio development.
               </p>
            </Article>
         </Container>
      </div>
   );
};

export default About;
