import { Row, Col } from 'reactstrap';
import { IWindowDimensions } from '../hooks/useWindowDimentions';

export interface ICvSectionItemProps {
   title: string;
   timeline: string;
   desc?: string;
   list?: string[];
   dimentions?: IWindowDimensions;
}

const CvSectionItem = (props: ICvSectionItemProps) => {
   const { dimentions, title, timeline, desc, list } = props;

   const isLargeScreen = (dimentions: IWindowDimensions | undefined) => {
      if (!dimentions) {
         return false;
      }
      return dimentions.width >= 600;
   };

   return (
      <div className="section-item">
         <Row>
            <Col lg={2} className="section-item-timeline">
               {timeline}
            </Col>
            <Col lg={8} className="section-item-title">
               {title}
            </Col>
         </Row>
         {desc && (
            <Row>
               {isLargeScreen(dimentions) && <Col lg="2" sm={true} />}
               <Col lg="8" className="section-item-desc">
                  {desc}
               </Col>
            </Row>
         )}
         {list && (
            <Row>
               {isLargeScreen(dimentions) && <Col lg="2" sm={true} />}
               <Col lg="8">
                  <ul className="section-item-list">
                     {list.map((item) => (
                        <li>{item}</li>
                     ))}
                  </ul>
               </Col>
            </Row>
         )}
      </div>
   );
};

export default CvSectionItem;
