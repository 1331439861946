import * as Tone from 'tone';

export interface NoteEvent {
   pitch: string;
   time: Tone.TimeClass;
}

export class Sequencer {
   currentStep: number;
   events: NoteEvent[];
   isPlaying: boolean;
   onNext: Function | null = null;

   constructor() {
      this.events = [];
      this.currentStep = 0;
      this.isPlaying = false;
   }

   addNotes(events: NoteEvent[]) {
      this.events = events;
   }

   addStepHandler(fn: Function) {
      this.onNext = fn;
   }

   private wait(t: number) {
      return new Promise((resolve) => setTimeout(resolve, t));
   }

   private async play() {
      if (this.onNext) {
         while (this.isPlaying) {
            if (this.currentStep === this.events.length) {
               this.reset();
               this.isPlaying = false;
               break;
            }

            this.onNext(this.currentStep);
            await this.wait(this.events[this.currentStep].time.toMilliseconds());
            this.currentStep++;
         }
      }
   }

   reset() {
      this.currentStep = 0;
   }

   start() {
      this.isPlaying = true;
      this.play();
   }

   stop() {
      this.isPlaying = false;
      this.currentStep = 0;
   }
}
