import { Link } from 'react-router-dom';
import Card from '../components/Card';
import CardDeck from '../components/CardDeck';
import random from '../util/random';
import { IFeaturePageData } from '../pages/features/types';

interface IProjectGridProps {
   projects: IFeaturePageData;
   images: string[];
   path: string;
}

const ProjectGrid = (props: IProjectGridProps) => {
   const { projects, images, path } = props;
   return (
      <CardDeck>
         {Object.keys(projects).map((k, i) => (
            <Link key={`card-${i}`} to={`${path}/${k}`}>
               <Card
                  title={projects[k].title}
                  year={projects[k].year}
                  footer={projects[k].description || projects[k].instr}
                  image={images[random(images.length, i + 1)]}
               />
            </Link>
         ))}
      </CardDeck>
   );
};

export default ProjectGrid;
