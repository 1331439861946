// import Image from '../components/Image';
// import img from '../assets/imgs/dots.png';

import Visual from '../components/visual/Visual';
const Home = () => (
   <div className="text-center my-4">
      {/* <Image src={img} alt="grid of circles and crescents" /> */}
      <Visual />
   </div>
);

export default Home;
