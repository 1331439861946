import { ReactChildren, ReactChild } from 'react';

export interface ICardDeck {
   children: ReactChildren | ReactChild[] | ReactChild;
}

const CardDeck = (props: ICardDeck) => {
   return <div className="card-grid row row-cols-1 row-cols-lg-4 row-cols-md-2">{props.children}</div>;
};

export default CardDeck;
