import { useState } from 'react';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import { useLocation } from 'react-router-dom';

import '../assets/css/nav.scss';

const NavigationBar = () => {
   const [isOpen, setIsOpen] = useState(false);
   const { pathname } = useLocation();

   const toggle = () => setIsOpen(!isOpen);

   return (
      <div>
         <Navbar color="light" light expand="md">
            <NavbarBrand href="/">daniel reid.</NavbarBrand>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
               <Nav className="navbar-nav ml-auto justify-content-end">
                  <NavItem>
                     <NavLink className={pathname === '/about' ? 'active' : ''} href="/about">
                        about.
                     </NavLink>
                  </NavItem>
                  <NavItem>
                     <NavLink className={pathname === '/cv' ? 'active' : ''} href="/cv">
                        cv.
                     </NavLink>
                  </NavItem>
                  {/* <NavItem>
                     <NavLink className={pathname === '/code' ? 'active' : ''} href="/code">
                        code.
                     </NavLink>
                  </NavItem> */}
                  {/* <NavItem>
                     <NavLink className={pathname === '/sound' ? 'active' : ''} href="/sound">
                        sound.
                     </NavLink>
                  </NavItem> */}
                  {/* <NavItem>
                     <NavLink className={pathname === '/blog' ? 'active' : ''} href="/blog">
                        writing.
                     </NavLink>
                  </NavItem> */}
               </Nav>
            </Collapse>
         </Navbar>
      </div>
   );
};

export default NavigationBar;
