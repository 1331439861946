import { Container } from 'reactstrap';
import ProjectGrid from '../components/ProjectGrid';
import images from '../assets/imgs/osc-placeholders';
import soundProjects from '../pages/features/sound';

const Sound = () => (
   <Container>
      <ProjectGrid projects={soundProjects} images={images} path="sound" />
   </Container>
);

export default Sound;
