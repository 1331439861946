export interface ICvSectionProps {
   title: string;
   items: any[];
}

const CvSection = (props: ICvSectionProps) => {
   return (
      <article className="section">
         <span className="section-title">{props.title}</span>
         {props.items}
      </article>
   );
};

export default CvSection;
