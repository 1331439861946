const baseSeed = new Date().getHours();
const stepSize = Math.max(new Date().getDate() * 500, 1);

const m = 4294967296;
const a = 1664525;
const c = 1013904223;

const random = (limit: number, step: number) => {
   let z = (baseSeed + step * stepSize) >>> 0;
   z = (a * z + c) % m;
   return Math.floor((z / m) * limit);
};

export default random;
