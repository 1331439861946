import * as Tone from 'tone';
import p5Types from 'p5';
import { NoteEvent } from './Sequencer';

export class GraphicNote {
   protected p5: p5Types;

   x: number;
   y: number;
   size: number;
   isHovered: boolean;
   noteEvent: NoteEvent;

   constructor(p5: p5Types, x: number, y: number, size: number) {
      this.p5 = p5;
      this.x = x;
      this.y = y;
      this.size = size;
      this.isHovered = false;
      this.noteEvent = { pitch: 'C4', time: Tone.Time('1n') };
   }

   onPlay() {}

   isIntersecting(shape: GraphicNote) {
      return this.p5.dist(shape.x, shape.y, this.x, this.y) < shape.size + this.size;
   }

   hovered(x: number, y: number) {
      this.isHovered = this.p5.dist(x, y, this.x, this.y) <= this.size;
      return this.isHovered;
   }

   update(x: number, y: number) {
      this.x = x;
      this.y = y;
   }

   render(v = 0) {
      // this.p5.noStroke();
      this.p5.fill(0);
      this.p5.ellipse(this.x, this.y, this.size, this.size);
   }
}
