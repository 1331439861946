import { Container } from 'reactstrap';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { IFeaturePageData } from '../pages/features/types';
import Article from './Article';

import '../assets/css/feature-page.scss';

interface IMatchParams {
   projectKey: string;
}

interface IFeaturePageProps extends RouteComponentProps<IMatchParams> {
   category: string;
   featureData: IFeaturePageData;
}

const FeaturePage = (props: IFeaturePageProps) => {
   const { match, featureData } = props;
   const page = featureData[match.params.projectKey];

   return (
      <Container>
         {page ? (
            <Article title={page.title} image={page.image}>
               {page.jsx}
            </Article>
         ) : (
            <div>no page found for {match.params.projectKey}</div>
         )}
      </Container>
   );
};

export default withRouter(FeaturePage);
