import { IFeatureImage } from '../pages/features/types';
import '../assets/css/image.scss';

const Image = (props: IFeatureImage) => {
   const { src, alt, link, caption } = props;

   return (
      <div className="img-container">
         <figure>
            <img src={src} alt={alt} />
            {caption && <figcaption>{caption}</figcaption>}
            {link && (
               <a href={link.url} target="_blank" rel="noreferrer">
                  <p className="img-link">{link.text}</p>
               </a>
            )}
         </figure>
      </div>
   );
};

export default Image;
