import imgSrc from '../../../assets/imgs/malachy-robinson.png';

export const onHearingYellowishBlue = {
   title: 'On hearing yellowish-blue',
   key: 'on-hearing-yellowish-blue',
   instr: 'for solo db. & laptop',
   year: 2019,
   tags: ['solo', 'electro-acoustic', 'double bass'],
   image: {
      src: imgSrc,
      alt: 'dancers',
      caption: 'Premiered April 18th 2019 at Studio 10, Dublin',
   },
   jsx: (
      <div className="performance-info">
         <p>
            <span className="font-medium">Premiered by:</span>
            <ul>
               <li>Malachy Robinson (double bass)</li>
               <li>Daniel Reid (laptop)</li>
            </ul>
         </p>
      </div>
   ),
};
