import { Container } from 'reactstrap';
import ProjectGrid from '../components/ProjectGrid';
import images from '../assets/imgs/pattern-placeholders';
import codeProjects from '../pages/features/code';

const Code = () => (
   <Container>
      <ProjectGrid projects={codeProjects} images={images} path="code" />
   </Container>
);

export default Code;
