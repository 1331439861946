import '../assets/css/card.scss';

export interface ICardProps {
   title: string;
   year?: number;
   image?: string;
   footer?: string;
}

const Card = (props: ICardProps) => {
   return (
      <div className="col">
         <div className="card" style={{ width: '18rem' }}>
            <img className="card-img-top" src={props.image} alt="card" />
            <div className="card-body">
               <h5 className="text-center card-title">{props.title}</h5>
               <p className="card-text">{props.year}</p>
               <p className="card-text">
                  <small className="text-muted">{props.footer}</small>
               </p>
            </div>
         </div>
      </div>
   );
};

export default Card;
