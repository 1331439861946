import imgSrc from '../../../assets/imgs/tracestimeleaves.png';

export const tracesTimeLeaves = {
   title: 'Traces Time Leaves',
   key: 'traces-time-leaves',
   instr: 'for flute w/ glissando head joint & laptop',
   year: 2018,
   tags: ['solo', 'electro-acoustic', 'flute'],
   image: {
      src: imgSrc,
      alt: 'dancers',
      caption: `Premiered Febuary 26th, 2018 at St Finian's Church, Dublin`,
   },
   jsx: (
      <div className="performance-info">
         <p>
            <span className="font-medium">Premiered by:</span>
            <ul>
               <li>Bill Dowdall (Flute)</li>
               <li>Daniel Reid (laptop)</li>
            </ul>
         </p>
      </div>
   ),
};
