import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import NavigationBar from './components/NavigationBar';
import FeaturePage from './components/FeaturePage';
import About from './pages/About';
import Home from './pages/Home';
import Code from './pages/Code';
import Sound from './pages/Sound';
import Cv from './pages/Cv';

import codeFeatureData from './pages/features/code';
import soundFeatureData from './pages/features/sound';

const App = () => {
   return (
      <Router>
         <NavigationBar />
         <Switch>
            <Route path="/about">
               <About />
            </Route>
            <Route path="/code/:projectKey">
               <FeaturePage category="code" featureData={codeFeatureData} />
            </Route>
            <Route path="/code">
               <Code />
            </Route>
            <Route path="/sound/:projectKey">
               <FeaturePage category="code" featureData={soundFeatureData} />
            </Route>
            <Route path="/sound">
               <Sound />
            </Route>
            <Route path="/cv">
               <Cv />
            </Route>
            <Route path="/">
               <Home />
            </Route>
         </Switch>
      </Router>
   );
};

export default App;
