import * as Tone from 'tone';
import p5js from 'p5';

import { GraphicNote } from './GraphicNote';
import { Cresent } from './Cresent';
import { Sequencer } from './Sequencer';
import { IWindowDimensions } from '../../../hooks/useWindowDimentions';

const scale = ['F#2', 'A2', 'F#3', 'C#4', 'A3', 'E4', 'G#4', 'B4', 'C#3', 'E3', 'A#4', 'G#3'];

export class Score {
   p5: p5js;
   notes: GraphicNote[];
   connections: GraphicNote[][];
   sequencer: Sequencer;
   polySynth: Tone.PolySynth;
   noteCount: number;
   window: IWindowDimensions;

   private grahicSize: number;

   constructor(p5: p5js, polySynth: Tone.PolySynth, dims: IWindowDimensions) {
      this.p5 = p5;
      this.notes = [];
      this.noteCount = dims.width > 600 ? 80 : 40;
      this.connections = [];
      this.sequencer = new Sequencer();
      this.polySynth = polySynth;
      this.grahicSize = Math.floor(this.p5.height / this.noteCount);
      this.window = dims;
   }

   init() {
      const hborder = Math.max(Math.floor(this.p5.height * 0.15), 35);
      const wborder = Math.max(Math.floor(this.p5.width * 0.15), 35);

      let pNote: GraphicNote | undefined;
      for (let i = 0; i < this.noteCount; i++) {
         let x = -1;
         let y = -1;

         let attempts = 0;
         const maxAttemps = 100000;

         let nextNote: GraphicNote | undefined;
         do {
            // Try to find a position that doesn't intersect with an existing note

            if (pNote && Math.random() < 0.75) {
               // put new note on the same steam as previous
               x = pNote.x;
            } else {
               // put it in random location
               x = this.p5.random(wborder, this.p5.width - wborder);
            }

            y = this.p5.random(hborder, this.p5.height - hborder);

            const size = this.p5.random(this.grahicSize / 2, this.grahicSize);
            const tmp = new Cresent(this.p5, x, y, size);

            // eslint-disable-next-line no-loop-func
            if (!this.notes.some((n) => n.isIntersecting(tmp))) {
               attempts = 0;
               nextNote = tmp;
               break;
            }

            attempts++;
         } while (!nextNote && attempts < maxAttemps);

         if (nextNote) {
            this.notes.push(nextNote);
            pNote = nextNote;
         }
      }

      const ns = [];
      for (let i = 0; i < this.notes.length; i++) {
         const note = this.notes[i];

         const pitch = scale[i % scale.length];
         const timeValue = ['1n,', '2n', '4n', '8n', '16n', '32n'][Math.floor(Math.random() * 5)];
         note.noteEvent = { pitch, time: Tone.Time(timeValue) };

         let other = this.notes.find((otherNote) => otherNote !== note && otherNote.x === note.x);
         if (other) {
            ns.push(note);
            this.connections.push([other, note]);
         }
      }

      this.notes = ns;

      this.sequencer.addNotes(this.notes.map((s) => s.noteEvent));
      this.sequencer.addStepHandler(this.onNextNote.bind(this));
   }

   /**
    * Called by the Sequence on each new step
    *
    * @param step The current sequencer step
    */
   private onNextNote(step: number) {
      const curr = this.notes[step];
      if (curr) {
         curr.onPlay();
         this.polySynth.triggerAttackRelease(curr.noteEvent.pitch, curr.noteEvent.time.toNotation());
      }
   }

   checkMouse(x: number, y: number) {
      for (const s of this.notes) {
         if (s.hovered(x, y)) {
            return true;
         }
      }

      return false;
   }

   pressed(x: number, y: number, polySynth: Tone.PolySynth, length: number) {
      // if (!this.sequencer.isPlaying) {
      //    this.sequencer.start();
      // } else {
      //    this.sequencer.stop();
      // }
   }

   render() {
      const drawBeams = true;

      let pConnection;
      for (const c of this.connections) {
         const [note1, note2] = c;

         this.p5.strokeCap(this.p5.SQUARE);
         this.p5.stroke(0);
         this.p5.strokeWeight(0.75);

         // connect the note to the note below it
         this.p5.line(note1.x, note1.y, note2.x, note2.y);

         if (drawBeams && pConnection) {
            const [pNote1] = pConnection;

            const thres = this.p5.width / 4;
            if (note1.x !== pNote1.x && Math.abs(note1.x - pNote1.x) < thres) {
               // connect the note to the beam
               this.p5.strokeWeight(0.75);
               this.p5.line(note1.x, 40, note1.x, note2.y);

               // beam the previous note to the current note
               this.p5.strokeWeight(4.75);
               this.p5.line(pNote1.x - 0.25, 40, note1.x + 0.25, 40);
            }
         }

         pConnection = c;
      }

      for (const s of this.notes) {
         if (this.sequencer.isPlaying && this.notes.indexOf(s) === this.sequencer.currentStep) {
            s.render(0);
         } else {
            s.render(1);
         }
      }
   }
}
