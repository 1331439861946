import { ReactChildren, ReactChild } from 'react';
import { IFeatureImage } from '../pages/features/types';
import Image from '../components/Image';

import '../assets/css/article.scss';

export interface IArticleProps {
   title?: string;
   image?: IFeatureImage;
   imageLink?: string;
   altText?: string;
   children: ReactChildren | ReactChild[] | ReactChild | JSX.Element;
}

const Article = (props: IArticleProps) => {
   const { title, image, children } = props;
   return (
      <article>
         {title && <h2 className="article-title">{props.title}</h2>}
         {image && <Image src={image.src} alt={image.alt} link={image.link} caption={image.caption} />}
         <div className="article-content">{children}</div>
      </article>
   );
};

export default Article;
