import p5Types from 'p5';
import { GraphicNote } from './GraphicNote';

export class Cresent extends GraphicNote {
   offset: number;
   radius: number;
   overlayRadius: number;

   constructor(p5: p5Types, x: number, y: number, size: number) {
      super(p5, x, y, size);
      this.radius = size;
      this.overlayRadius = this.radius * 0.95;

      this.offset = this.p5.random(this.size / 4, this.size);
      if (Math.random() < 0.5) {
         this.offset *= -1;
      } else if (Math.random() < 0.65) {
         this.offset = 0;
      }
   }

   isIntersecting(note: Cresent) {
      return (
         this.p5.dist(note.x, note.y, this.x, this.y) < (note.radius + this.radius) * 3 &&
         this.p5.dist(note.x, note.y + this.offset, this.x, this.y + this.offset) <
            (note.overlayRadius + this.overlayRadius) * 3
      );
   }

   /**
    * Renders a black elipse with a white eilipse on top
    */
   render(v = 0) {
      this.p5.noStroke();

      if (v === 1) {
         this.p5.fill(0);
      } else {
         this.p5.fill(255, 0, 0);
      }

      this.p5.ellipse(this.x, this.y, this.size, this.size);

      this.p5.fill(255);
      this.p5.ellipse(this.x, this.y + this.offset, this.overlayRadius, this.overlayRadius);
   }
}
