import imgSrc from '../../../assets/imgs/everything-takes-time.png';

export const everythingTakesTime = {
   title: 'Everything Takes Time',
   key: 'everything-takes-time',
   instr: 'for vc, sax, & perc.',
   year: 2016,
   tags: ['dance', 'acoustic'],
   image: {
      src: imgSrc,
      alt: 'dancers',
      caption: 'Premiered July 18th, 2016 at Smock Alley, Dublin',
      link: {
         url: 'https://www.ste.ie/',
         text: 'photo by Ste Murray',
      },
   },
   jsx: (
      <div className="performance-info">
         <p>
            <span className="font-medium">Premiered by:</span>
            <ul>
               <li>Kate Ellis (cello)</li>
               <li>Nick Roth (saxophone)</li>
               <li>Francesco Turrisi (percussion)</li>
            </ul>
         </p>
         <p>
            <span className="font-medium">Dancers:</span>
            <ul>
               <li>Jessie Keenan</li>
               <li>Lucia Kickham</li>
               <li>Marion Cronin</li>
            </ul>
         </p>
         <p>Choreography by Jessie Keenan</p>
      </div>
   ),
};
