import Sketch from 'react-p5';
import p5js from 'p5';
import * as Tone from 'tone';

import useWindowDimensions from '../../hooks/useWindowDimentions';

import { Score } from './lib/Score';

const Visual = () => {
   const dimensions = useWindowDimensions();

   let score: Score;

   let synth: Tone.PolySynth;
   const length = 2;

   const setup = (p5: p5js, canvasParentRef: Element) => {
      p5.createCanvas(p5.windowWidth, p5.windowHeight - 150).parent(canvasParentRef);
      p5.smooth();
      p5.ellipseMode(p5.RADIUS);

      const reverb = new Tone.Reverb({
         decay: 6,
         preDelay: 0.01,
      }).toDestination();

      synth = new Tone.PolySynth(undefined, {
         volume: -25,
         oscillator: {
            type: 'triangle',
         },
         envelope: {
            attack: 0.8,
            decay: 0.75,
            sustain: 0.4,
            release: 1,
         },
      }).toDestination();

      synth.connect(reverb);

      score = new Score(p5, synth, dimensions);
      score.init();
   };

   const draw = (p5: p5js) => {
      if (score) {
         p5.background(255);
         p5.stroke(130);
         p5.strokeWeight(0.5);
         // p5.rect(2, 2, p5.width - 4, p5.height - 4);
         score.render();
      }
   };

   const mouseMoved = (e: any) => {
      if (e && score) {
         const keyHovered = score.checkMouse(e.mouseX, e.mouseY);
         if (keyHovered) {
            score.p5.cursor(score.p5.HAND);
         } else {
            score.p5.cursor(score.p5.ARROW);
         }
      }
   };

   const mousePressed = (e: any) => {
      Tone.start().then(() => {
         if (e && score) {
            score.pressed(e.mouseX, e.mouseY, synth, length);
         }
      });
   };

   return (
      <>
         {/* <h2 className="title">kbrd.</h2> */}
         <Sketch setup={setup} draw={draw} mouseMoved={mouseMoved} mousePressed={mousePressed} />
      </>
   );
};

export default Visual;
